import React           from 'react';
import { Link }        from 'gatsby';
import { Divider }     from '../../../../lib/components/divider/divider';
import { Title }       from '../../../../lib/components/title/title';
import { Wrapper }     from '../../../../lib/components/wrapper/wrapper.component';
import { cn }          from '../../../../lib/util/bem';
import { md }          from '../../../../lib/util/md';
import { getAllPosts } from './get-content';

const className = cn('content');

const Item = (props: {
    slug: string;
    title: string;
    section: number;
    index: number;

}) => (
    <Wrapper
        padding="sm"
        margin={ { bottom: 'sm' } }
        hover={ { color: 'secondary' } }
        xsm={ { padding: 'xxs', margin: { bottom: 'off' }, color: 'key' } }
    >
        <Link
            className={ className('item') }
            to={ `${ props.slug.substring(1) }` }
            state={ {
                modal:    true,
                noScroll: true
            } }
            style={ { width: '100%' } }
        >
            <Wrapper>
                { /* <Divider margin="md"  /> */ }
                <Wrapper row>
                    <Title
                        size="h4"
                        xsm={ { size: 'h5' } }
                        color="key"
                        margin="md"
                        weight="bold"
                        hover={ { color: 'key' } }
                        style={ { fontWeight: 400 } }
                    >
                        { `${ props.section }.${ props.index }` }
                    </Title>
                    <Title
                        size="h4"
                        xsm={ { size: 'h5' } }
                        // uppercase
                        margin="md"
                        hover={ { color: 'key' } }
                        style={ { fontWeight: 400 } }
                    >
                        { props.title }
                    </Title>

                </Wrapper>
            </Wrapper>
        </Link>
    </Wrapper>
);

export type ChapterType = 'secondary'|'general'|'primary'

export const ContentHandbook = (props: {year: string; chapter: ChapterType}) => {
    const content = getAllPosts();

    return (
        <Wrapper className={ className() }>
            { content.map((section, idx) => (
                <Wrapper key={ idx } frame="solid-grey" margin="md" radius="lg" padding="xxl" sm={ { padding: 'sm' } }>
                    { /* <Divider margin="md" color="key" /> */ }

                    <Title
                        size="h2"
                        // uppercase
                        maxWidth="90rem"
                        sm={ { size: 'h4' } }
                        hover={ { color: 'key' } }
                    >
                        <Link
                            className={ className('item') }
                            to={ section.fields.slug ? section.fields.slug.substring(1) : undefined }
                            state={ {
                                modal:    true,
                                noScroll: true
                            } }
                            style={ { width: '100%' } }
                        >
                            { md(section.frontmatter.title) }
                        </Link>
                    </Title>

                    { !!section.tableOfContents.items && (
                        <>
                            <Divider transparent margin="xxl" sm={ { margin: 'md' } } />
                            <Wrapper
                                row
                                styles={ { flexWrap: 'wrap' } }
                                columns={ 4 }
                                md={ { columns: 2 } }
                                sm={ { columns: 1 } }
                            >
                                { section.tableOfContents.items.map((heading, hindex) => {
                                    if (heading.title) {
                                        return (
                                            <Item
                                                key={ hindex }
                                                section={ idx + 1 }
                                                index={ hindex + 1 }
                                                slug={ `${ section.fields.slug }${ heading.url }` }
                                                title={ heading.title }
                                            />
                                        );
                                    }
                                    return null;
                                }) }
                            </Wrapper>
                        </>
                    ) }

                </Wrapper>
            )) }
        </Wrapper>
    );
};
